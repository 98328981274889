import axios from "axios";

let instance = axios.create();

//对axios的配置
instance.defaults.timeout = 10000;
instance.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
instance.defaults.baseURL = "/officialsite";

instance.CancelToken = axios.CancelToken;
//添加请求拦截器
instance.interceptors.request.use(
    config => {
        // config.url.includes("/inneruser/handleoncetoken")
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

//返回状态判断(添加响应拦截器)
instance.interceptors.response.use(
    res => {
        return res.data;
    },
    error => {
        return Promise.reject(error);
    },
);
export default instance
