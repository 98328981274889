<template>
  <div class="pc">
    <div class="header">
      <div class="header_bg">
        <div class="header_box">
          <div class="header_left">
            <img class="header_logo" src="@/assets/images/img_banner_title.png" alt="">
            <a target="_blank" href="https://survey.cpsol.net/s/p1HP98" class="header_free">立即免费测评</a>
            <p class="header_btns">
              <a @click="zixun">
                <i class="iconfont icon-zixun"></i>
                <label>在线咨询</label>
              </a>
              <a @click="showhow">
                <i class="iconfont icon-yanshi"></i>
                <label>查看演示</label>
              </a>
            </p>
          </div>
          <div class="header_right">
            <p class="right_share" @click="share">
              <i class="iconfont icon-fenxiang"></i>
              <span>分享</span>
            </p>
            <video class="right_img" autoplay loop muted :controls="false">
              <source src="@/assets/images/ping_web.webm" type="video/webm">
            </video>
          </div>
        </div>
      </div>
    </div>
    <!--评测报告-->
    <div class="evaluating">
      <div class="left">
        <p class="title">全方位的评估报告</p>
        <p class="desc">
          由包装产业互联网研究院，通过对全国1000多家纸板企业经营现状分析，通过20余项综合模块现状结果确认，大数据自动生成测评报告及全流程测评结果，输出相应整改建议，能够更好为企业经营提供决策的方向</p>
        <a class="btns" @click="showhow">
          <i class="iconfont icon-yanshi"></i>
          <span>查看评估报告演示</span>
        </a>
      </div>
      <div class="right">
        <img src="@/assets/images/img_detail1.jpg" alt="">
      </div>
    </div>
    <!--产品优势-->
    <div class="advantage">
      <div class="advantage_box">
        <p>产品优势</p>
        <img src="@/assets/images/img_detail2.png" alt="">
      </div>
    </div>
    <!--操作步骤-->
    <div class="operation_steps">
      <p class="title">操作步骤</p>
      <img src="@/assets/images/img_detail3.png" alt="">
      <p class="btn">
        <a target="_blank" href="https://survey.cpsol.net/s/p1HP98">立即免费测评</a>
      </p>
    </div>
    <!--客户案例-->
    <div class="customer_case">
      <div class="case_box">
        <p class="title">客户案例</p>
        <p class="images">
          <img src="@/assets/images/img_case1.png" alt="">
          <img src="@/assets/images/img_case2.png" alt="">
          <img src="@/assets/images/img_case3.png" alt="">
        </p>
      </div>
    </div>
    <!--底部-->
    <div class="footer">
      <div class="footer_top">
        <div class="research">
          <p>包装产业互联网研究院</p>
          <p>总部：温州市瓯海经济开发区东经一路1号</p>
          <p>工业园：温州104国道丽岱段东经路一号</p>
          <p>邮编：325014</p>
          <a target="_blank" class="address"
             href="https://map.baidu.com/poi/%E4%B8%9C%E7%BB%8F%E5%8C%85%E8%A3%85/@13433203.828105312,3224019.2503220835,14.85z?uid=a1f42a7bcda00729e6e96558&primaryUid=16460277307192112855&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl">
            <i class="iconfont icon-common_footor_iocn_navigation"></i>
            <span>一键导航</span>
          </a>
        </div>
        <div class="contact_us">
          <p>联系我们</p>
          <p>客户服务热线：400-133-6161</p>
          <p>联系电话：0577-85399999</p>
          <p>E-mail：djcps@djcps.com</p>
        </div>
        <div class="scan">
          <p class="title">扫一扫</p>
          <div class="box">
            <div class="box_ev">
              <img src="@/assets/images/djyiwang.png" alt="">
              <p>东经易网公众号</p>
            </div>
            <div class="box_ev">
              <img src="@/assets/images/zhuanjiacode.png" alt="">
              <p>联系客服</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_lower">
        <p class="copyright">版权所有 © 2011-{{ year }} 浙ICP备15023330号-3</p>
        <p class="record">
          <img src="@/assets/images/jinghui.png" alt="">
          <span>浙公网安备 33030402000738号</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import yanshi from "@/assets/images/yanshi.png";
import utils from "@/utils/utils";
import {getSystemDate} from "@/api/common";

export default {
  name: "pc",
  data() {
    return {
      year: ""
    };
  },
  created() {
    this.getYear();
  },
  methods: {
    async getYear() {
      let res = await getSystemDate();
      if (res.success) {
        this.year = res.data.systemDate;
      }
    },
    zixun() {
      utils.zixun();
    },
    showhow() {
      window.open(yanshi);
    },
    share() {
      this.copy(window.location.href);
      this.$message("复制链接成功，快去分享吧");
    },
    async copy(text) {
      let target = document.createElement('input'); //创建input节点
      target.value = text; // 给input的value赋值
      document.body.appendChild(target); // 向页面插入input节点
      target.select(); // 选中input
      try {
        await document.execCommand('Copy'); // 执行浏览器复制命令
        await target.remove(); // 删除节点
      } catch {
        console.log('复制失败');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pc {
  .header {
    .header_bg {
      max-width: 1920px;
      height: 630px;
      background-image: url("../assets/images/img_banner_bg.png");
      background-size: cover;
      background-position: 50%;
      margin: 0 auto;
    }
    .header_box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .header_left {
    }
    .header_logo {
      width: 646px;
      height: 282px;
      margin-top: 46px;
    }
    .header_free {
      margin-top: 61px;
      width: 424px;
      height: 88px;
      background: linear-gradient(180deg, #CAEEFF 0%, #F0FAFF 100%) #FFFFFF;
      border-radius: 4px;
      font-size: 28px;
      color: #3654EA;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .header_free:hover {
      background: #CFE5FF;
    }
    .header_btns {
      display: flex;
      margin-top: 30px;
    }
    .header_btns a {
      width: 196px;
      height: 54px;
      border-radius: 4px;
      border: 1px solid #FFFFFF;
      margin-left: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #FFFFFF;
      cursor: pointer;
      i {
        margin-right: 16px;
      }
      label {
      }
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        background: #FFFFFF;
        color: #1083FE;
      }
    }
    .header_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 44px;
      .right_share {
        width: 107px;
        height: 48px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        font-size: 20px;
        color: #1083FE;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          margin-right: 14px;
        }
        span {
        }
        &:hover {
          background: #FFFFFF;
          border: 1px solid #FFFFFF;
        }
      }
      .right_img {
        width: 517px;
        height: 495px;
      }
    }
  }
  .evaluating {
    width: 1200px;
    height: 684px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 545px;
      .title {
        font-size: 32px;
        color: #333333;
        line-height: 42px;
      }
      .desc {
        font-size: 18px;
        color: #666666;
        line-height: 39px;
        margin-top: 44px;
      }
      .btns {
        width: 260px;
        height: 54px;
        border-radius: 4px;
        border: 1px solid #1083FE;
        font-size: 18px;
        color: #1083FE;
        cursor: pointer;
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin-right: 16px;
        }
        span {
        }
        &:hover {
          background: #1083FE;
          border: 1px solid #1083FE;
          color: #FFFFFF;
        }
      }
    }
    .right {
      img {
        width: 577px;
        height: 525px;
      }
    }
  }
  .advantage {
    background-color: #1083FE;
    height: 600px;
    overflow: hidden;
    .advantage_box {
      width: 1200px;
      margin: 0 auto;
      p {
        font-size: 32px;
        color: #FFFFFF;
        line-height: 44px;
        margin-top: 80px;
        text-align: center;
      }
      img {
        width: 100%;
        height: 355px;
        margin-top: 40px;
      }
    }
  }
  .operation_steps {
    width: 1200px;
    margin: 0 auto;
    height: 723px;
    text-align: center;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 44px;
      text-align: center;
      padding-top: 60px;
    }
    .btn {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 326px;
        height: 70px;
        background: #1083FE;
        border-radius: 4px;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 31px;
        cursor: pointer;
        &:hover {
          background: #0471E7;
        }
      }
    }
    img {
      width: 1130px;
      height: 350px;
      margin-top: 40px;
    }
  }
  .customer_case {
    background: #1083FE;
    height: 745px;
    .case_box {
      width: 1200px;
      margin: 0 auto;
      .title {
        padding-top: 60px;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
      }
      .images {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        img {
          width: 384px;
          height: 520px;
        }
      }
    }
  }
  .footer {
    max-width: 1920px;
    height: 424px;
    margin: 0 auto;
    background-image: url('../assets/images/home_bottom_bg.png');
    background-size: auto 100%;
    background-position: 50%;
    overflow: hidden;
    .footer_top {
      margin-top: 70px;
      display: flex;
      justify-content: center;
      .research {
        p {
          &:nth-child(1) {
            font-size: 16px;
            color: #333333;
            line-height: 21px;
          }
          &:nth-child(2) {
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            margin-top: 30px;
          }
          &:nth-child(3) {
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            margin-top: 13px;
          }
          &:nth-child(4) {
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            margin-top: 13px;
          }
        }
        .address {
          margin-top: 26px;
          width: 128px;
          height: 40px;
          border-radius: 5px;
          border: 1px solid rgba(51, 51, 51, .2);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
          i {
            margin-right: 5px;
          }
          span {
          }
        }
      }
      .contact_us {
        margin-left: 145px;
        p {
          &:nth-child(1) {
            font-size: 16px;
            color: #333333;
            line-height: 21px;
          }
          &:nth-child(2) {
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            margin-top: 30px;
          }
          &:nth-child(3) {
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            margin-top: 13px;
          }
          &:nth-child(4) {
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            margin-top: 13px;
          }
        }
      }
      .scan {
        margin-left: 145px;
        .title {
          font-size: 16px;
          color: #333333;
          line-height: 21px;
        }
        .box {
          display: flex;
          margin-top: 30px;
          .box_ev {
            width: 128px;
            height: 155px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 40px;
            &:first-child {
              margin-left: 0;
            }
            img {
              width: 112px;
              height: 112px;
              margin-top: 8px;
            }
            p {
              font-size: 13px;
              color: #666666;
              line-height: 17px;
              margin-top: 7px;
            }
          }
        }
      }
    }
    .footer_lower {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 110px;
      .copyright {
        font-size: 12px;
        color: #333333;
        line-height: 16px;
      }
      .record {
        display: flex;
        align-items: center;
        margin-left: 168px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          vertical-align: middle;
        }
        span {
          font-size: 12px;
          color: #333333;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
