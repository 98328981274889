<template>
  <div class="indexMobile">
    <div class="header">
      <img class="header_banner" src="@/assets/images/img_banner.png" alt="">
      <img @click="share" class="share" src="@/assets/images/btn_banner_share.png" alt="">
      <video class="header_video" autoplay loop muted :controls="false">
        <source src="@/assets/images/ping_h5.webm" type="video/webm">
      </video>
      <div class="free">
        <p class="btn_banner_test_bg" @click="toLink('https://survey.cpsol.net/s/p1HP98')"></p>
      </div>
      <p class="zixun">
        <img @click="zixun" src="@/assets/images/btn_banner_server.png" alt="">
        <img @click="showhow" src="@/assets/images/btn_banner_demo.png" alt="">
      </p>
    </div>
    <div class="report">
      <img class="banner" src="@/assets/images/img_detail1_mobile.png" alt="">
      <p class="btn_box">
        <img @click="showhow" class="btn" src="@/assets/images/btn_mock1_demo.png" alt="">
      </p>
    </div>
    <div class="advantage">
      <img src="@/assets/images/img_detail2_mobile.png" alt="">
      <img src="@/assets/images/img_detail3_mobile.png" alt="">
      <img src="@/assets/images/img_detail41.png" alt="">
      <img src="@/assets/images/img_detail42.png" alt="">
    </div>
    <div class="footer">
      <div class="research">
        <p>包装产业互联网研究院</p>
        <p>总部：温州市瓯海经济开发区东经一路1号</p>
        <p>邮编：325014</p>
        <a class="adress" target="_blank"
           href="https://map.baidu.com/poi/%E4%B8%9C%E7%BB%8F%E5%8C%85%E8%A3%85/@13433203.828105312,3224019.2503220835,14.85z?uid=a1f42a7bcda00729e6e96558&primaryUid=16460277307192112855&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl">
          <i class="iconfont icon-common_footor_iocn_navigation"> </i>
          <span>一键导航</span>
        </a>
      </div>
      <div class="contact_us">
        <p>联系我们</p>
        <p>客户服务热线：400-133-6161</p>
        <p>联系电话：0577-85399999</p>
        <p>E-mail：djcps@djcps.com</p>
      </div>
      <div class="scan">
        <p class="title">扫一扫</p>
        <div class="scan_box">
          <div class="scan_ev">
            <img src="@/assets/images/djyiwang.png" alt="">
            <p>东经易网公众号</p>
          </div>
          <div class="scan_ev">
            <img src="@/assets/images/zhuanjiacode.png" alt="">
            <p>联系客服</p>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p class="banquan">版权所有 © 2011-{{ year }} 浙ICP备15023330号-3</p>
        <p class="beian">
          <img src="@/assets/images/jinghui.png" alt="">
          <span>浙公网安备 33030402000738号</span>
        </p>
      </div>
      <FreeEvaluation/>
    </div>
  </div>
</template>

<script>
import FreeEvaluation from "@/views/components/freeEvaluation";
import yanshi from "@/assets/images/yanshi.png";
import utils from "@/utils/utils";
import {getSystemDate} from "@/api/common";

export default {
  name: "indexMobile",
  components: {FreeEvaluation},
  data() {
    return {
      year: ""
    };
  },
  created() {
    this.getYear();
  },
  methods: {
    async getYear() {
      let res = await getSystemDate();
      if (res.success) {
        this.year = res.data.systemDate;
      }
    },
    zixun() {
      utils.zixun();
    },
    showhow() {
      window.open(yanshi);
    },
    toLink(url) {
      window.open(url);
    },
    share() {
      this.copy(window.location.href);
      this.$message("复制链接成功，快去分享吧");
    },
    async copy(text) {
      let target = document.createElement('input'); //创建input节点
      target.value = text; // 给input的value赋值
      document.body.appendChild(target); // 向页面插入input节点
      target.select(); // 选中input
      try {
        await document.execCommand('Copy'); // 执行浏览器复制命令
        await target.remove(); // 删除节点
      } catch {
        console.log('复制失败');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.indexMobile {
  padding-bottom: 0.7rem;
  background-color: #F4F5F7;
  .header {
    position: relative;
    font-size: 0;
    .header_banner {
      width: 100%;
    }
    .share {
      position: absolute;
      width: 0.65rem;
      height: 0.4rem;
      top: 1.5rem;
      left: 0.155rem;
    }
    .header_video{
      position: absolute;
      width: 3.34rem;
      height: 2.94rem;
      top: 1.55rem;
      left: 0.155rem;
    }
    .free {
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 0.79rem;
      width: 100%;
      .btn_banner_test_bg {
        width: 3.23rem;
        height: 0.83rem;
        background: url("../assets/images/btn_banner_test.png") no-repeat;
        background-size: 100%;
      }
    }
    .zixun {
      width: 100%;
      position: absolute;
      bottom: 0.09rem;
      display: flex;
      justify-content: center;
      img {
        width: 1.56rem;
        height: 0.66rem;
        &:first-child {
          margin-right: 0.055rem;
        }
      }
    }
  }
  .report {
    position: relative;
    text-align: center;
    font-size: 0;
    .banner {
      width: 100%;
    }
    .btn_box {
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 0.28rem;
      width: 100%;
      .btn {
        width: 2.3rem;
        height: 0.72rem;
      }
    }
  }
  .advantage {
    font-size: 0rem;
    img {
      width: 100%;
    }
  }
  .footer {
    background-image: url("../assets/images/img_footor_bg_mobile.png");
    background-size: 100% 100%;
    background-position: 50%;
    height: 6.075rem;
    padding: 0 0.3rem;
    overflow: hidden;
    .research {
      p {
        &:nth-child(1) {
          font-size: 0.13rem;
          color: #333333;
          line-height: 0.18rem;
          margin-top: 0.46rem;
        }
        &:nth-child(2) {
          font-size: 0.12rem;
          color: #666666;
          line-height: 0.16rem;
          margin-top: 0.12rem;
        }
        &:nth-child(3) {
          font-size: 0.12rem;
          color: #666666;
          line-height: 0.16rem;
          margin-top: 0.08rem;
        }
      }
      .adress {
        width: 0.9rem;
        height: 0.28rem;
        border-radius: 0.04rem;
        border: 1px solid rgba(51, 51, 51, .2);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.11rem;
        color: #333333;
        margin-top: 0.2rem;
        i {
          margin-right: 0.02rem;
        }
        span {
        }
      }
    }
    .contact_us {
      p {
        &:nth-child(1) {
          font-size: 0.13rem;
          color: #333333;
          line-height: 0.18rem;
          margin-top: 0.4rem;
        }
        &:nth-child(2) {
          font-size: 0.12rem;
          color: #666666;
          line-height: 0.16rem;
          margin-top: 0.12rem;
        }
        &:nth-child(3) {
          font-size: 0.12rem;
          color: #666666;
          line-height: 0.16rem;
          margin-top: 0.08rem;
        }
        &:nth-child(4) {
          font-size: 0.12rem;
          color: #666666;
          line-height: 0.16rem;
          margin-top: 0.08rem;
        }
      }
    }
    .scan {
      .title {
        font-size: 0.13rem;
        color: #333333;
        line-height: 0.18rem;
        margin-top: 0.4rem;
      }
      .scan_box {
        display: flex;
        margin-top: 0.22rem;
        .scan_ev {
          width: 1.02rem;
          height: 1.24rem;
          background: #FFFFFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0.3rem;
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 0.905rem;
            height: 0.905rem;
            margin-top: 0.06rem;
          }
          p {
            font-size: 0.12rem;
            color: #666666;
            line-height: 0.16rem;
            margin-top: 0.036rem;
          }
        }
      }
    }
    .copyright {
      margin-top: 0.3rem;
      .banquan {
        font-size: 0.12rem;
        color: #333333;
        line-height: 0.16rem;
      }
      .beian {
        display: flex;
        align-items: center;
        margin-top: 0.08rem;
        img {
          width: 0.14rem;
          height: 0.15rem;
        }
        span {
          font-size: 0.12rem;
          color: #333333;
          line-height: 0.16rem;
          margin-left: 0.025rem;
        }
      }
    }
  }
}

</style>
