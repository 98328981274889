<template>
  <div class="index">
    <IndexPc v-if="isPc"/>
    <IndexMobile v-else/>
  </div>
</template>

<script>
import IndexPc from "@/views/pc";
import IndexMobile from "@/views/indexMobile";

export default {
  name: "index",
  components: {IndexPc, IndexMobile},
  data() {
    return {
      isPc: true
    };
  },
  created() {
    this.monitorWidth();
  },
  methods: {
    monitorWidth() {
      let width = document.body.offsetWidth || document.body.clientWidth;
      if (width >= 1200) {
        this.isPc = true;
      } else {
        this.isPc = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.index {
}
</style>
