<template>
  <div class="free_evaluation" v-show="isShow">
    <img @click="zixun" class="zixun" src="@/assets/images/btn_set_server.png" alt="">
    <img @click="toLink('https://survey.cpsol.net/s/p1HP98')" class="ceping" src="@/assets/images/btn_set_test.png" alt="">
  </div>
</template>

<script>
import utils from "@/utils/utils"

export default {
  name: "freeEvaluation",
  data() {
    return {
      isShow: false
    };
  },
  created() {
    this.onscroll();
  },
  methods: {
    zixun() {
      utils.zixun()
    },
    toLink(url){
      window.open(url)
    },
    onscroll() {
      window.onscroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= 626) {
          this.isShow = true;
        }else{
          this.isShow = false;
        }
      };
    }
  },
};
</script>

<style scoped lang="scss">
.free_evaluation {
  position: fixed;
  width: 100%;
  bottom: 0.15rem;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  .zixun {
    width: 0.6rem;
    height: 0.6rem;
  }
  .ceping {
    width: 2.85rem;
    height: 0.68rem;
    margin-left: 0.03rem;
  }
}

</style>
