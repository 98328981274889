import api from "../axios";

// 获取版权年份
export const getSystemDate = (params) => {
    return api({
        method: 'post',
        url: '/official/getSystemDate.do',
        data: params
    });
};
